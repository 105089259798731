const BillboardMap = () => import(/* webpackChunkName: "SovMap" */ '../views/BillboardMap');

const billboardMapRouter = [
    {
      path: '/billboard-map',
      name: 'billboardmap',
      component: BillboardMap,
      meta: { isLinkAja: true }
    }
]

export default billboardMapRouter;
